// MainDialog.tsx

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  styled,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface MainDialogProps {
  onClose: () => void;
  onOutsideClick: () => void;
  dialogTitle: string;
  dialogDescription: string;
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  entityDocuments?: any[];
  filteredDocumentGroup?: (filteredDocs: any[]) => void;
  showLocationFilter?: boolean;
}

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  position: "relative",
  padding: "5px 24px",
  "& .MuiTypography-root": {
    // marginBottom: theme.spacing(1),
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(-1),
  top: theme.spacing(-1),
  color: theme.palette.grey[500],
}));

const sampleData = [
  { id: 2, location: "United Kingdom" },
  { id: 3, location: "Australia" },
  { id: 4, location: "United States" },
  { id: 5, location: "Canada" },
];

const MainDialog: React.FC<MainDialogProps> = ({
  onClose,
  onOutsideClick,
  dialogTitle,
  dialogDescription,
  children,
  width = "95vw",
  height = "95vh",
  minWidth = "800px",
  minHeight = "450px",
  entityDocuments,
  filteredDocumentGroup,
  showLocationFilter = true,
}) => {
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState<
    number | null
  >(null);

  const handleChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedDocumentTypeId(selectedValue);
  };

  useEffect(() => {
    if (showLocationFilter) {
      filteredDocumentGroup && filteredDocumentGroup([]);
    }
  }, [showLocationFilter, filteredDocumentGroup]);

  useEffect(() => {
    if (selectedDocumentTypeId) {
      const filterType = entityDocuments.filter(
        (doc) => doc.documentGroupId === selectedDocumentTypeId
      );
      filteredDocumentGroup(filterType);
    }
  }, [entityDocuments, selectedDocumentTypeId]);

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onOutsideClick();
        } else {
          onClose();
        }
      }}
      maxWidth={"xl"}
      PaperProps={{
        style: {
          width: width,
          height: height,
          minWidth: minWidth,
          minHeight: minHeight,
          padding: "3px 10px",
          maxWidth: "80vw !important",
        },
      }}
    >
      <CustomDialogTitle>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h6" sx={{ flex: 1 }}>
            {dialogTitle}
            <Typography variant="body1">{dialogDescription}</Typography>
          </Typography>
          {showLocationFilter && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "auto",
                mr: "1em",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  padding: "5px",
                  textAlign: "left",
                }}
              >
                Please select the Location
              </Typography>

              <FormControl
                style={{
                  width: "300px",
                  display: "flex",
                  height: "100%",
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#e0e0e0",
                    },
                    "&:hover fieldset": {
                      borderColor: " #bdbdbd ",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#bdbdbd",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px",
                    },
                  },
                }}
              >
                <InputLabel
                  sx={{
                    padding: "0.1rem 0.2rem",
                  }}
                >
                  Select Location
                </InputLabel>
                <Select
                  value={selectedDocumentTypeId}
                  label="Select Location"
                  onChange={handleChange}
                >
                  {sampleData.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      </CustomDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default MainDialog;
